import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Button, Card, Col, Container, OverlayTrigger, ProgressBar, Row, Tooltip } from 'react-bootstrap';
import Breadcrumb from 'Common/BreadCrumb';
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import SimpleBar from 'simplebar-react';
import { Link } from 'react-router-dom';
import { fetchProductDetails, getProductDocumentation,  } from '../../../Common/data/productoverview';
import hljs from 'highlight.js';

import Reviews from "./Reviews";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

// Import Images
import productsImg31 from "../../../assets/images/products/img-124.jpg";
import productsImg32 from "../../../assets/images/products/img-125.jpg";
import productsImg33 from "../../../assets/images/products/img-126.jpg";
import productsImg34 from "../../../assets/images/products/img-127.jpg";

import avatar1 from "../../../assets/images/users/avatar-1.jpg";
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar5 from "../../../assets/images/users/avatar-5.jpg";
import avatar8 from "../../../assets/images/users/avatar-8.jpg";    

// SwiperCore.use([FreeMode, Navigation, Thumbs]);

const Overview = () => {

    document.title = "Product Overview | AeroPartX";

    const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);
    const { productId } = useParams<{ productId: string }>();
    const [productDetails, setProductDetails] = useState<any>(null);
    const [productDocumentation, setProductDocumentation] = useState<{ documentation: string | null }>({ documentation: null });

    useEffect(() => {
        async function fetchData() {
            try {
                if (!productId) {
                    // Handle the case when productId is undefined
                    return;
                }

                const productDetailsData = await fetchProductDetails(productId);
                setProductDetails(productDetailsData);
                console.log('Product Details:', productDetailsData);
            } catch (error: any) {
                // Handle errors
                console.error('Error:', error.message);
            }
        }

        fetchData();
    }, [productId]);

    // Fetch product documentation using the API call
    useEffect(() => {
        const fetchProductDocumentation = async () => {
            try {
                const documentation = await getProductDocumentation(productId || 'defaultProductId');
                setProductDocumentation({ documentation });
            } catch (error: any) {
                console.error('Error fetching product documentation:', error.message);
            }
        };

        fetchProductDocumentation();
    }, [productId]);

    // Format the date
    const formattedDate = productDetails ? new Date(productDetails.productCreated).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    }) : '';


    //tooltip
    const renderTooltip = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            Click to view
        </Tooltip>
    );

    const highlightCode = (code: string) => {
        if (!code) {
          return { __html: '' }; // Return an empty object or handle accordingly
        }
      
        const highlightedCode = hljs.highlightAuto(code).value;
        return { __html: highlightedCode };
    };  

    const escapeHTML = (html: string) => {
        if (!html) {
          return { __html: '' }; // Return an empty object or handle accordingly
        }
      
        return { __html: html.replace(/</g, '&lt;').replace(/>/g, '&gt;') };
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumb title="Overview" pageTitle="Product" />
                    <Row className="gx-lg-4">
                        <Col xl={4} lg={8} className="mx-auto">
                            <Row className="sticky-side-div">
                                <Col lg={2}>
                                    <Swiper 
                                        onSwiper={setThumbsSwiper} 
                                        direction={"vertical"} 
                                        slidesPerView={4}
                                        freeMode={true} 
                                        modules={[FreeMode, Navigation, Thumbs]}
                                        watchSlidesProgress={true} 
                                        spaceBetween={10} 
                                        className="productSwiper productswiper-2 mb-3 mb-lg-0"
                                    >
                                        {/* thumbsSlider */}
                                        <div className="swiper-wrapper product-wrapper">
                                            {Array.isArray(productDetails?.productImage)
                                                ? productDetails.productImage.map((image: string, index: number) => (
                                                    <SwiperSlide key={index}>
                                                        <div className="product-thumb rounded cursor-pointer">
                                                            <img src={image} alt={`Product Image ${index + 1}`} className="img-fluid" />
                                                        </div>
                                                    </SwiperSlide>
                                                ))
                                                : (
                                                    <SwiperSlide>
                                                        <div className="product-thumb rounded cursor-pointer">
                                                            <img
                                                                src={productDetails?.productImage}
                                                                alt="Product Image"
                                                                className="img-fluid"
                                                            />
                                                        </div>
                                                    </SwiperSlide>
                                                )
                                            }
                                        </div>

                                    </Swiper>
                                </Col>
                                <Col lg={10}>
                                    <div className="bg-light rounded-2 position-relative ribbon-box overflow-hidden">

                                        <Swiper 
                                            navigation={true} 
                                            modules={[FreeMode, Navigation, Thumbs]} 
                                            thumbs={{ swiper: thumbsSwiper }} 
                                            className="productSwiper2">
                                            <div className="swiper-wrapper">
                                                {/* Check if productDetails is available before rendering the image */}
                                                {productDetails && (
                                                    <SwiperSlide>   
                                                        <img src={productDetails.productImage} alt={productDetails.productName} className="img-fluid" />
                                                    </SwiperSlide>
                                                )}
                                            </div>
                                        </Swiper>

                                    </div>
                                </Col>
                            </Row>
                        </Col>

                        <Col xl={8}>
                            <div className="mt-5 mt-xl-0">
                                <div className="d-flex">
                                    <div className="flex-grow-1">
                                        <h4> {productDetails?.productName} </h4>
                                        <div className="hstack gap-3 flex-wrap">
                                            <div className="text-muted">Published : <span className="text-body fw-medium"> {formattedDate} </span></div>
                                        </div>
                                    </div>
                                    <div className="flex-shrink-0">
                                        <OverlayTrigger placement="top" overlay={<Tooltip id="top"> Edit </Tooltip>}>
                                            <Link to={`/product-create-edit/${productId}`} className="btn custom-soft-secondary-btn btn-icon">
                                                <i className="ri-pencil-fill align-bottom"></i>
                                            </Link>
                                        </OverlayTrigger>
                                    </div>
                                </div>

                                <div className="mt-4 text-muted">
                                    <h5 className="fs-15">Description :</h5>
                                    <p dangerouslySetInnerHTML={escapeHTML(productDetails?.productDescription)} />
                                    
                                    <h6 className="fs-15 mb-3">Compatibility :</h6>
                                    <ul className="list-unstyled vstack gap-2">
                                        <li>
                                            <i className="bi bi-check2-circle me-2 align-middle text-success" />
                                            Aircraft Model : <b>{productDetails?.productModel}</b>
                                        </li>
                                        <li>
                                            <i className="bi bi-check2-circle me-2 align-middle text-success" />
                                            Year of Manufacture: <b>{productDetails?.productYear}</b>
                                        </li>
                                    </ul>
                                    {productDocumentation?.documentation && productDocumentation.documentation !== '#' && (
                                        <ul className="list-unstyled vstack gap-2">
                                            <li>
                                                <i className="bi bi-check2-circle me-2 align-middle text-success" />
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={renderTooltip}
                                            >
                                                <a href={productDocumentation.documentation} target="_blank" rel="noopener noreferrer">
                                                    Documentation 
                                                </a>
                                            </OverlayTrigger>
                                            </li>
                                        </ul>
                                    )}
                                </div>

                                <h5 className="fs-15">Product Details:</h5>
                                <div className="table-responsive">
                                    <table className="table table-sm table-borderless align-middle description-table">
                                        <tbody>
                                            <tr>
                                                <th>Condition</th>
                                                <td> {productDetails?.type} </td>
                                            </tr>
                                            <tr>
                                                <th>FAA</th>
                                                <td>Approved</td>
                                            </tr>
                                            <tr>
                                                <th>EASA</th>
                                                <td>Certified</td>
                                            </tr>
                                            <tr>
                                                <th>ISO</th>
                                                <td>9001:2015</td>
                                            </tr>
                                            <tr>
                                                <th>Location</th>
                                                <td> {productDetails?.city} </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                {/*<Reviews />*/}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default Overview;