import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Container, Row, Alert } from 'react-bootstrap';
import Breadcrumb from 'Common/BreadCrumb';
import { Link, useParams, useNavigate } from 'react-router-dom'; 
import { addProductDocument } from 'Common/data/adddocumentation';
import ProgressBar from '../CreateProduct/ProgressBar';

const CreateProductThird = () => {
  document.title = 'Create Product | AeroPartX';

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false); // State for loading spinner
  const { productID } = useParams<{ productID: string }>();
  const [activeStep, setActiveStep] = useState(3);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [error, setError] = useState<string>('');

  const navigate = useNavigate();

  useEffect(() => {
    console.log('Selected Files:', selectedFiles);
  }, [selectedFiles]);

  function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    const files = event.target.files;
    if (files) {
      const fileList = Array.from(files);
      setSelectedFiles(fileList);
    }
  }

  const uploadDocuments = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
  
    try {
      // Check if no files are selected
      if (selectedFiles.length === 0) {
        setError('Please select at least one document.');
        return;
      }

      setIsLoading(true); // Start loading spinner

      const formData = new FormData();

      // Append productID to the FormData only if it is defined
      if (productID !== undefined) {
        formData.append('productID', productID);
      } else {
        // Handle the case where productID is undefined
        console.error('productID is undefined');
        return;
      }
  
      // Append files to the FormData
      for (const file of selectedFiles) {
        formData.append('productdoc', file);

        // Append filename to the FormData
        formData.append('name', file.name);
      }
  
      const apiResponse = await addProductDocument(formData);

      if (apiResponse && apiResponse.data && apiResponse.data.ResponseCode === '00') {
        setShowSuccessAlert(true); // Show success alert

        // After 5 seconds, redirect to the next page
        setTimeout(() => {
          navigate('/products-list'); 
        }, 3000);
      }
  
    } catch (error: any) {
      console.error('Error uploading documents:', error.message);
    } finally {
      setIsLoading(false); // Stop loading spinner
    }
  };

  /* Formats the size */
  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumb title="Create Product" pageTitle="Products" />
          <ProgressBar activeStep={activeStep}/>
          <form id="createproduct-form" autoComplete="off" className="needs-validation" noValidate>
            <Row>
              <Col xl={9} lg={8}>
                <Card>
                  <Card.Header>
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar-sm">
                          <div className="avatar-title rounded-circle custom-bg-light custom-text-primary fs-20">
                            <i className="bi bi-file-text"></i>
                          </div>
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="card-title mb-1">Part Gallery</h5>
                        <p className="text-muted mb-0">Add part gallery documents.</p>
                      </div>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <div className="my-dropzone">
                      <input
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={handleFileChange}
                      />
                    </div>
                    {/* Display error message */}
                    {error && (
                      <Alert variant="danger" className="mt-3">
                        {error}
                      </Alert>
                    )}
                    {/* Display image previews */}
                    <div className="mt-3">
                      {selectedFiles.map((file, index) => (
                        <Card
                          key={index}
                          className="mt-1 mb-0 shadow-none border"
                        >
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <div className="file-icon">
                                  <i className="ri-file-2-line display-4" />
                                </div>
                              </Col>
                              <Col>
                                <Link to="#" className="text-muted font-weight-bold">
                                  {file.name}
                                </Link>
                                <p className="mb-0">
                                  <strong>{formatBytes(file.size)}</strong>
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      ))}
                    </div>
                    <div className="error-msg mt-1">Please add part document.</div>
                  </Card.Body>
                </Card>

                <div className="text-end mb-3">
                    <Button
                        variant="success"
                        type="submit"
                        className="w-sm custom-primary-btn"
                        onClick={uploadDocuments}
                        disabled={isLoading}
                    >
                        {isLoading ? (
                          <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        ) : (
                          'Submit'
                        )}
                    </Button>
                    <Link to="/" className="btn btn-light w-sm">
                        Skip
                    </Link>
                </div>
                {/* Success alert */}
                {showSuccessAlert && (
                  <Alert variant="success" onClose={() => setShowSuccessAlert(false)} dismissible>
                    Product document added successfully!
                  </Alert>
                )}
              </Col>
            </Row>
          </form>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateProductThird;
