import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { loginUser, resetLoginFlag, socialLogin } from 'slices/thunk';
import { getVendorByEmail } from 'Common/data/getvendorbyemail';

import GoogleLoginUnavailableAlert from './GoogleLoginUnavailableAlert';

import withRouter from 'Common/withRouter';
import logoDark from 'assets/images/logo-dark.png';
import logoLight from 'assets/images/logo-light.png';

const Login = (props: any) => {
    document.title = 'Login | AeroPartX';

    const dispatch = useDispatch<any>();
    const selectUser = (state: any) => state.Account.user;
    const selectError = (state: any) => state.Login.error;

    const selectUserData = createSelector(
        selectUser,
        selectError,
        (user, error) => ({
            user,
            error,
        })
    );
    const { user, error } = useSelector(selectUserData);

    const [userLogin, setUserLogin] = useState<any>({ email: '', password: '' });
    const [passwordShow, setPasswordShow] = useState<any>(false);
    const [loader, setLoader] = useState<boolean>(false);
    const [rememberMe, setRememberMe] = useState<boolean>(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        if (user && user) {
            setUserLogin({
                email: user.email,
                password: user.password,
            });
        }
    }, [user]);

    useEffect(() => {
        const storedEmail = localStorage.getItem('rememberedEmail');
        const storedPassword = localStorage.getItem('rememberedPassword');

        if (storedEmail && storedPassword) {
            setUserLogin({
                email: storedEmail,
                password: storedPassword,
            });
        }
    }, []);

    const validation: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: userLogin.email || '',
            password: userLogin.password || '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required('Please Enter Your Email'),
            password: Yup.string().required('Please Enter Your Password'),
        }),
        onSubmit: async (values) => {

            const customerDetails = await getVendorByEmail(values.email);

            if (customerDetails.status === 0) {
                setErrorMessage('Please check for an email from AeroPartX to verify your account before you can login.');
                setShowErrorAlert(true);
                return;
            } else if (customerDetails.status === 2) {
                setErrorMessage('Your account is inactive. Please send a message to the admin to unblock at aeropartx.com/#/contact');
                setShowErrorAlert(true);
                return;
            } else {
                dispatch(loginUser(values, props.router.navigate));
                setLoader(true);

                if (rememberMe) {
                    localStorage.setItem('rememberedEmail', values.email);
                    localStorage.setItem('rememberedPassword', values.password);
                } else {
                    localStorage.removeItem('rememberedEmail');
                    localStorage.removeItem('rememberedPassword');
                }
            }
        },
    });

    const handleRememberMeChange = () => {
        setRememberMe(!rememberMe);
    };

    useEffect(() => {
        setTimeout(() => {
            dispatch(resetLoginFlag());
        }, 3000);
        setLoader(false);
    }, [dispatch, error]);

    const handleGoogleLogin = async (response: any) => {
        console.log("Google login response:", response);
        
        if (response.email && response.email_verified === true) {
            try {
                // Dispatch the socialLogin action with the email from the response
                dispatch(socialLogin(response.email, navigate));
            } catch (error:any) {
                console.error('Error with social login:', error.message);
                // Handle error
            }
        } else {
            console.error('Google login response does not contain necessary details:', response);
            // Handle error
        }
    };
    

    return (
        <React.Fragment>
            <section className="auth-page-wrapper position-relative bg-light min-vh-100 d-flex align-items-center justify-content-between">
                <div className="auth-header position-fixed top-0 start-0 end-0 bg-body">
                    <Container fluid={true}>
                        <Row className="justify-content-between align-items-center">
                            <Col className="col-2">
                                <Link className="navbar-brand mb-2 mb-sm-0" to="https://www.aeropartx.com/#/dashboard">
                                    <img src={logoDark} className="card-logo card-logo-dark" alt="logo dark" height="22" />
                                    <img src={logoLight} className="card-logo card-logo-light" alt="logo light" height="22" />
                                </Link>
                            </Col>
                            <Col className="col-auto">
                                <ul className="list-unstyled hstack gap-2 mb-0">
                                    <li className="me-md-3">
                                        <Link to="#" className="text-body fw-medium fs-15">Become a Buyer</Link>
                                    </li>
                                    <li className="d-none d-md-block">
                                        <Link to="https://www.aeropartx.com/#/auth-signup-basic" className="btn custom-soft-primary-btn" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="align-middle" />Sign Up
                                        </Link>
                                    </li>
                                    <li className="d-none d-md-block">
                                        <Link to="https://www.aeropartx.com/#/auth-signin-basic" className="btn custom-soft-secondary-btn" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="align-middle" />Sign In
                                        </Link>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="w-100">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={6}>
                                <div className="auth-card mx-lg-3">
                                    <Card className="border-0 mb-0">
                                        <div className="custom-bg-primary border-0 px-5 py-3 rounded-top">
                                            <h1 className="text-white text-center fw-lighter">AeroPartX Vendor</h1>
                                        </div>
                                        <Card.Body>
                                            <p className="text-muted fs-15">Sign In to continue to AeroPartX.</p>
                                            <div className="p-2">
                                                {showErrorAlert && (
                                                    <Alert variant="danger" style={{ marginTop: "13px" }}>
                                                        {errorMessage}
                                                    </Alert>
                                                )}

                                                {error && error ? (<Alert variant="danger"> Username and password are invalid. </Alert>) : null}

                                                <Form action="#" onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}>

                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="username">Email</Form.Label>
                                                        <Form.Control 
                                                            name="email" 
                                                            type="email" 
                                                            className="form-control" 
                                                            id="username" 
                                                            placeholder="Enter email"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.email || ""}
                                                            isInvalid={
                                                                validation.touched.email && validation.errors.email ? true : false
                                                            }
                                                        />
                                                        {validation.touched.email && validation.errors.email ? (
                                                            <Form.Control.Feedback type="invalid">{validation.errors.email}</Form.Control.Feedback>
                                                        ) : null}
                                                    </div>

                                                    <div className="mb-3">
                                                        <div className="float-end">
                                                            <Link to="/forgot-password" className="text-muted">Forgot password?</Link>
                                                        </div>
                                                        <Form.Label htmlFor="password-input">Password</Form.Label>
                                                        <div className="position-relative auth-pass-inputgroup mb-3">
                                                            <Form.Control
                                                                className={`form-control pe-5 password-input ${validation.touched.password && validation.errors.password ? 'is-invalid' : ''}`}
                                                                placeholder="Enter password"
                                                                id="password-input"
                                                                name="password"
                                                                value={validation.values.password || ""}
                                                                type={passwordShow ? "text" : "password"}
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                            />
                                                            {validation.touched.password && validation.errors.password ? (
                                                                <Form.Control.Feedback type="invalid">{validation.errors.password}</Form.Control.Feedback>
                                                            ) : null}
                                                            {!validation.touched.password || !validation.errors.password ? (
                                                                <Button variant='link' className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}>
                                                                    <i className="ri-eye-fill align-middle"></i>
                                                                </Button>
                                                            ) : null}
                                                        </div>
                                                    </div>

                                                    <div className="form-check">
                                                        <Form.Check 
                                                            type="checkbox" 
                                                            id="auth-remember-check" 
                                                            checked={rememberMe}
                                                            onChange={handleRememberMeChange}
                                                        />
                                                        <Form.Label htmlFor="auth-remember-check">Remember me</Form.Label>
                                                    </div>

                                                    <div className="mt-4">
                                                        <Button variant='primary' className="w-100 custom-primary-btn" type="submit" disabled={!error ? loader : false}>{!error ? loader && <Spinner size="sm" animation="border" className="me-2" /> : ""}Sign In</Button>
                                                    </div>

                                                    <div className="mt-4 pt-2 text-center">
                                                        <div className="signin-other-title">
                                                            <h5 className="fs-13 mb-4 title">Sign In with</h5>
                                                        </div>

                                                        <GoogleLoginUnavailableAlert/>

                                                        <div className="pt-2 hstack gap-2 justify-content-center">
                                                            <GoogleLogin
                                                                onSuccess={credentialResponse => {
                                                                    const credential = credentialResponse.credential;
                                                                    if (credential) {
                                                                        const credentialResponseDecoded = jwtDecode(credential);
                                                                        console.log(credentialResponseDecoded);
                                                                        handleGoogleLogin(credentialResponseDecoded);
                                                                    } else {
                                                                        console.error('Credential is undefined');
                                                                    }             
                                                                }}
                                                                onError={() => {
                                                                    console.log('Login Failed');
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </Form>

                                                <div className="text-center mt-5">
                                                    <p className="mb-0">Don't have an account ? <Link to="/register" className="fw-semibold text-secondary text-decoration-underline"> Sign Up</Link> </p>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                    <footer className="footer">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <div className="text-center">
                                        <p className="mb-0 text-muted">©
                                            {(new Date().getFullYear())} AeroPartX. Site by <a href="https://cloudcraves.com" target="_blank" rel="noopener noreferrer">CloudCrave</a>
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </footer>
                </div>
            </section>
        </React.Fragment>
    );
};

export default withRouter(Login);