import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import Breadcrumb from 'Common/BreadCrumb';
import { useNavigate, useParams } from 'react-router-dom';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { fetchProductDetails } from '../../../Common/data/productoverview';
import { updateProduct } from 'Common/data/updateProduct';
import { CategoryList, fetchCategoryList } from '../../../Common/data/category';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CreateProductEdit = () => {
    document.title = "Edit Product | AeroPartX";

    const { productId } = useParams();
    const [categoryList, setCategoryList] = useState<CategoryList[]>([]);
    const [selectedYear, setSelectedYear] = useState<Date | null>(null);
    const [productDetails, setProductDetails] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCategoryListData = async () => {
            try {
                const categoryData = await fetchCategoryList();
                setCategoryList(categoryData);
            } catch (error: any) {
                console.error('Error fetching product list:', (error as Error).message);
            }
        };

        fetchCategoryListData();
    }, []);

    useEffect(() => {
        const fetchProductData = async () => {
            try {
                if (productId) {
                    const productDetails = await fetchProductDetails(productId);
                    setProductDetails(productDetails);
                    console.log ("Checker", productDetails)
                } else {
                    console.error('Product ID is undefined');
                }
            } catch (error: any) {
                console.error('Error fetching product details:', error.message);
            }
        };

        fetchProductData();
    }, [productId]);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Part name is required'),
        description: Yup.string()
            .required('Please enter a part description.')
            .test(
                'wordCount',
                'Description must have at least 3 words',
                (value) => value.split(' ').filter(Boolean).length >= 3
            ),
        model: Yup.string().required('Aircraft model is required'),
        category: Yup.string().required('Part category is required'),
        productYear: Yup.string().required('Year of manufacture is required'),
        condition: Yup.string().required('Condition is required'),
        partnumber: Yup.string().required('Part number is required'),
    });

    const handleSubmit = async (values: any, actions: any) => {
        actions.setSubmitting(true);
        setLoading(true);

        try {
            const formDataToSend = {
                name: values.name,
                description: values.description,
                model: values.model,
                category: values.category,
                productYear: values.productYear,
                vendor: JSON.parse(localStorage.getItem('authUser') || '{}').vendorID || '',
                state: values.condition,
                manufacturers: "Not Updated",
                partnumber: values.partnumber,
                price: "0",
                productID: productId,
            };

            const apiResponse = await updateProduct(formDataToSend);

            if (apiResponse && apiResponse.status === '00') {
                navigate(`/`);
            } else {
                console.error('Error adding product:', apiResponse.message);
            }
        } catch (error: any) {
            console.error('Error:', error.message);
        } finally {
            setLoading(false);
            actions.setSubmitting(false);
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumb title="Edit Product" pageTitle="Products" />
                    {productDetails && (
                        <Formik
                            initialValues={{
                                name: productDetails.productName,
                                description: productDetails.productDescription,
                                model: productDetails.productModel,
                                category: productDetails.productCategories,
                                productYear: productDetails.productYear,
                                condition: productDetails.condition,
                                partnumber: productDetails.productPartNumber,
                            }}
                            onSubmit={(values, actions) => {
                                handleSubmit(values, actions);
                            }}
                            validationSchema={validationSchema}
                        >
                            {({ errors, touched, setFieldValue, handleSubmit }) => (
                                <Form
                                    id="createproduct-form"
                                    autoComplete="off"
                                    className="needs-validation"
                                    noValidate
                                    onSubmit={(event) => {
                                        event.preventDefault(); // Prevent default form submission behavior
                                        handleSubmit(); // Call Formik's handleSubmit function
                                    }}
                                >
                                    <Row>
                                        <Col xl={9} lg={8}>
                                            <Card>
                                                <Card.Header>
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0 me-3">
                                                            <div className="avatar-sm">
                                                                <div className="avatar-title rounded-circle custom-bg-light custom-text-primary fs-20">
                                                                    <i className="bi bi-box-seam"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <h5 className="card-title mb-1">Product Information</h5>
                                                            <p className="text-muted mb-0">Fill all information below.</p>
                                                        </div>
                                                    </div>
                                                </Card.Header>
                                                <Card.Body>
                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="product-title-input">Part name</Form.Label>
                                                        <Field
                                                            type="text"
                                                            id="product-title-input"
                                                            name="name"
                                                            placeholder="Enter part name"
                                                            className={`form-control ${errors.name && touched.name ? 'is-invalid' : ''}`}
                                                        />
                                                        <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                                    </div>
                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="product-number-input">Part number</Form.Label>
                                                        <Field
                                                            type="text"
                                                            id="product-number-input"
                                                            name="partnumber"
                                                            placeholder="Enter part number"
                                                            className={`form-control ${errors.partnumber && touched.partnumber ? 'is-invalid' : ''}`}
                                                        />
                                                        <ErrorMessage name="partnumber" component="div" className="invalid-feedback" />
                                                    </div>
                                                    <div className="mb-3">
                                                        <Form.Label>Part Description</Form.Label>
                                                        <Field
                                                            as="textarea"
                                                            id="product-description-textarea"
                                                            name="description"
                                                            rows={5}
                                                            placeholder="Enter part description"
                                                            className={`form-control ${errors.description && touched.description ? 'is-invalid' : ''}`}
                                                        />
                                                        <ErrorMessage name="description" component="div" className="invalid-feedback" />
                                                    </div>
                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="product-aircraft-model-input">Aircraft Model</Form.Label>
                                                        <Field
                                                            type="text"
                                                            id="product-aircraft-model-input"
                                                            name="model"
                                                            placeholder="Enter aircraft model"
                                                            className={`form-control ${errors.model && touched.model ? 'is-invalid' : ''}`}
                                                        />
                                                        <ErrorMessage name="model" component="div" className="invalid-feedback" />
                                                    </div>
                                                    <div className="mt-3">
                                                        <Form.Label htmlFor="product-year-input">Year of Manufacture</Form.Label>
                                                    </div>
                                                    <div className="mb-3">
                                                        <DatePicker
                                                            id="product-year-input"
                                                            selected={selectedYear}
                                                            onChange={(date: Date) => {
                                                                setSelectedYear(date);
                                                                setFieldValue('productYear', date.getFullYear().toString());
                                                            }}
                                                            dateFormat="yyyy"
                                                            showYearPicker
                                                            className={`form-control ${errors.productYear && touched.productYear ? 'is-invalid' : ''}`}
                                                        />
                                                        <ErrorMessage name="productYear" component="div" className="invalid-feedback" />
                                                    </div>
                                                    
                                                    <div className="mb-3">
                                                        <Form.Label>Part category</Form.Label>
                                                        <Field
                                                            as="select"
                                                            id="choices-category-input"
                                                            name="category"
                                                            className={`form-select ${errors.category && touched.category ? 'is-invalid' : ''}`}
                                                        >
                                                            <option value="">Select part category</option>
                                                            {categoryList.map(category => (
                                                                <option key={category.id} value={category.catID}>
                                                                    {category.catName}
                                                                </option>
                                                            ))}
                                                        </Field>
                                                        <ErrorMessage name="category" component="div" className="invalid-feedback" />
                                                    </div>
                                                    <div className="mb-3">
                                                        <Form.Label>Condition</Form.Label>
                                                        <Field
                                                            as="select"
                                                            id="choices-condition-input"
                                                            name="condition"
                                                            className={`form-select ${errors.condition && touched.condition ? 'is-invalid' : ''}`}
                                                        >
                                                            <option value="">Select condition</option>
                                                            <option value="1">New</option>
                                                            <option value="2">Refurbished</option>
                                                        </Field>
                                                        <ErrorMessage name="condition" component="div" className="invalid-feedback" />
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                            <div className="text-end mb-3">
                                                <Button
                                                    variant='success'
                                                    type="submit"
                                                    className="w-sm custom-primary-btn"
                                                    disabled={loading}
                                                >
                                                    {loading ? <Spinner animation="border" size="sm" /> : 'Submit'}
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    )}
                </Container>
            </div>
        </React.Fragment>
    );
}

export default CreateProductEdit;
